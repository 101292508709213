import { useEffect } from 'react';

import { DEFAULT_USER, GA4_KEY } from '../utils/constants';
import GAMES_CONFIG from '../utils/GamesConfig';
import { sendGTMEvent } from '../utils/analytics';

import useUrlParams from './useUrlParams';

const useSaveRefLinkToClipboard = (addEventListener, removeEventListener) => {
  const { app_id } = useUrlParams();
  useEffect(() => {
    const handleSaveRefLinkToClipboard = async () => {
      const tempInput = document.createElement('input');
      tempInput.value = `${GAMES_CONFIG[app_id || 1].telegramUrl}/app?startapp=${
        window.Telegram.WebApp.initDataUnsafe.user?.id || DEFAULT_USER.id
      }`;
      document.body.appendChild(tempInput);
      tempInput.select();

      try {
        const success = document.execCommand('copy');
        if (success) {
          alert(
            'Link copied to clipboard. You can now paste it into the desired chat.'
          );
        } else {
          alert('Failed to copy link. Please copy it manually.');
        }
      } catch (err) {
        console.error('Error copying link: ', err);
        alert(
          'An error occurred while copying the link. Please copy it manually.'
        );
      } finally {
        document.body.removeChild(tempInput);
        sendGTMEvent(GA4_KEY.unity_save_link_to_clipboard);
      }
    };

    addEventListener('SaveRefLinkToClipboard', handleSaveRefLinkToClipboard);
    return () =>
      removeEventListener(
        'SaveRefLinkToClipboard',
        handleSaveRefLinkToClipboard
      );
  }, [addEventListener, removeEventListener, app_id]);
};

export default useSaveRefLinkToClipboard;
