import { useEffect } from 'react';

import HapticSDK from '../sdk/HapticSDK';

const useHapticTrigger = (addEventListener, removeEventListener) => {
  useEffect(() => {
    addEventListener(
      'HapticTrigger',
      (message) => message && HapticSDK.trigger(message)
    );

    return () => {
      removeEventListener(
        'HapticTrigger',
        (message) => message && HapticSDK.trigger(message)
      );
    };
  }, [addEventListener, removeEventListener]);
};

export default useHapticTrigger;
