import { useEffect } from 'react';

// import axios from 'axios';

const usePlayTappads = (addEventListener, removeEventListener) => {
  const TappAdsPubSdk = window.TappAdsPubSdk;
  useEffect(() => {
    const handlePlayTappads = async (message) => {
      // const task = JSON.parse(message);
      // // window.Telegram.WebApp.openTelegramLink(task.url);
      // window.Telegram.WebApp.openLink(task.url);
      // const res = await axios.get(`${task.click_postback}`);

      // alert(res.data);
      console.log(message);
      const task = JSON.parse(message);
      TappAdsPubSdk.taskStart(task.id)
        .then(() => {
          console.log('Task started:', task.id);
        })
        .catch((err) => {
          console.error('Task start error:', err);
        });
    };
    addEventListener('PlayTappads', handlePlayTappads);
    return () => removeEventListener('PlayTappads', handlePlayTappads);
  }, [addEventListener, removeEventListener, TappAdsPubSdk]);
};

export default usePlayTappads;
