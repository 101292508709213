import { TonConnectUIError } from '@tonconnect/ui-react';
import { createPayment, getPaymentStatus } from '../api/payments';
import { cloudRemoveItem, cloudSetItem } from './cloudStorage';
import { storageKey } from './constants';

export const checkTransactionStatus = async (
  hash,
  paymentProduct,
  setPaymentProduct,
  sendMessage,
  setIsLoading,
  setIsVisible
) => {
  let attempts = 0;
  const maxAttempts = 10;
  const interval = 5000;

  const checkStatus = async () => {
    try {
      const status = await getPaymentStatus(hash);
      if (
        status.status === 'closed' ||
        status.status === 'expired' ||
        status.status === 'Order not found'
      ) {
        sendMessage(
          'TeleLauncherSDK',
          'OnPurchaseResult',
          JSON.stringify({
            product_id: paymentProduct,
            status: 2,
            hash: hash,
          })
        );
        setPaymentProduct(null);
        setIsLoading(false);
        setIsVisible(false);
        await cloudRemoveItem(storageKey.PAYMENT_HASH);
        return;
      }

      attempts++;
      if (attempts < maxAttempts) {
        setTimeout(checkStatus, interval);
      } else {
        alert('Maximum number of attempts reached, transaction status unknown');
        setPaymentProduct(null);
        setIsLoading(false);
        setIsVisible(false);
        sendMessage(
          'TeleLauncherSDK',
          'OnPurchaseResult',
          JSON.stringify({
            product_id: paymentProduct,
            status: 3,
            hash: hash,
          })
        );
        await cloudRemoveItem(storageKey.PAYMENT_HASH);
      }
    } catch (error) {
      console.error('Error while checking transaction status:', error);
    }
  };

  checkStatus();
};

export const getStarsPayment = async (
  paymentProduct,
  setPaymentProduct,
  sendMessage,
  setIsLoading,
  setIsVisible
) => {
  try {
    const data = await createPayment(paymentProduct, true);
    setIsLoading(true);
    sendMessage(
      'TeleLauncherSDK',
      'OnPurchaseResult',
      JSON.stringify({
        product_id: paymentProduct,
        status: 0,
        hash: data.hash,
      })
    );
    await cloudSetItem(storageKey.PAYMENT_HASH, {
      hash: data.hash,
      paymentProduct,
    });
    if (data.url) {
      window.Telegram.WebApp.openInvoice(data.url, async (status) => {
        let purchaseStatus;
        switch (status) {
          case 'paid':
            purchaseStatus = 2;
            break;
          case 'cancelled':
            purchaseStatus = 1;
            break;
          case 'failed':
            purchaseStatus = 3;
            break;
          case 'pending':
            purchaseStatus = 0;
            break;
          default:
            purchaseStatus = 3;
        }

        if (purchaseStatus === 1) {
          sendMessage(
            'TeleLauncherSDK',
            'OnPurchaseResult',
            JSON.stringify({
              product_id: paymentProduct,
              status: purchaseStatus,
              hash: data.hash,
            })
          );
          setIsLoading(false);
          setPaymentProduct(null);
          setIsVisible(false);
          await cloudRemoveItem(storageKey.PAYMENT_HASH);
        } else if (purchaseStatus === 2) {
          checkTransactionStatus(
            data.hash,
            paymentProduct,
            setPaymentProduct,
            sendMessage,
            setIsLoading,
            setIsVisible
          );
        } else {
          sendMessage(
            'TeleLauncherSDK',
            'OnPurchaseResult',
            JSON.stringify({
              product_id: paymentProduct,
              status: 3,
              hash: data.hash,
            })
          );
          setIsLoading(false);
          setPaymentProduct(null);
          setIsVisible(false);
          await cloudRemoveItem(storageKey.PAYMENT_HASH);
        }
      });
    } else {
      alert('Failed to create order. Please try again.');
      sendMessage(
        'TeleLauncherSDK',
        'OnPurchaseResult',
        JSON.stringify({
          product_id: paymentProduct,
          status: 3,
          hash: data.hash,
        })
      );
      setIsLoading(false);
      setPaymentProduct(null);
      setIsVisible(false);
      await cloudRemoveItem(storageKey.PAYMENT_HASH);
    }
  } catch (error) {
    alert('Failed to create order. Please try again.');
    setIsLoading(false);
    setPaymentProduct(null);
    setIsVisible(false);
  }
};

export const getTonPayment = async (
  paymentProduct,
  setPaymentProduct,
  sendMessage,
  tonConnectUI,
  setIsLoading,
  setIsVisible
) => {
  try {
    const data = await createPayment(paymentProduct, false);
    setIsLoading(true);
    if (data.address && data.amount) {
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 300,
        messages: [
          {
            address: data.address,
            amount: data.amount,
            payload: data.payload,
          },
        ],
      };
      sendMessage(
        'TeleLauncherSDK',
        'OnPurchaseResult',
        JSON.stringify({
          product_id: paymentProduct,
          status: 0,
          hash: data.hash,
        })
      );
      await cloudSetItem(storageKey.PAYMENT_HASH, {
        hash: data.hash,
        paymentProduct,
      });

      try {
        await tonConnectUI.sendTransaction(transaction);
        checkTransactionStatus(
          data.hash,
          paymentProduct,
          setPaymentProduct,
          sendMessage,
          setIsLoading,
          setIsVisible
        );
      } catch (tonError) {
        if (tonError instanceof TonConnectUIError) {
          sendMessage(
            'TeleLauncherSDK',
            'OnPurchaseResult',
            JSON.stringify({
              product_id: paymentProduct,
              status: 1,
              hash: data.hash,
            })
          );
          setPaymentProduct(null);
          setIsLoading(false);
          setIsVisible(false);
          await cloudRemoveItem(storageKey.PAYMENT_HASH);
        } else {
          throw tonError;
        }
      }
    } else {
      sendMessage(
        'TeleLauncherSDK',
        'OnPurchaseResult',
        JSON.stringify({
          product_id: paymentProduct,
          status: 3,
          hash: data.hash,
        })
      );
      setIsLoading(false);
      setPaymentProduct(null);
      setIsVisible(false);
      await cloudRemoveItem(storageKey.PAYMENT_HASH);
    }
  } catch (error) {
    setIsLoading(false);
    setPaymentProduct(null);
    setIsVisible(false);
    await cloudRemoveItem(storageKey.PAYMENT_HASH);
    sendMessage(
      'TeleLauncherSDK',
      'OnPurchaseResult',
      JSON.stringify({
        product_id: paymentProduct,
        status: 3,
      })
    );
  }
};
