const HapticType = [
    'Selection',
    'Success',
    'Warning',
    'Failure',
    'LightImpact',
    'MediumImpact',
    'HeavyImpact',
    'RigidImpact',
    'SoftImpact',
    'None',
  ];
  
  const HapticSDK = {
    isAvailable: () => {
      return !!(
        window.Telegram &&
        window.Telegram.WebApp &&
        window.Telegram.WebApp.HapticFeedback
      );
    },
  
    handleError: (message) => {
      console.warn(`HapticSDK: ${message}`);
    },
  
    impactOccurred: (style) => {
      if (!HapticSDK.isAvailable()) {
        HapticSDK.handleError(
          'Telegram WebApp is not available for haptic feedback'
        );
        return;
      }
  
      const validStyles = ['light', 'medium', 'heavy', 'rigid', 'soft'];
      if (!validStyles.includes(style)) {
        HapticSDK.handleError(
          `Invalid impact style: ${style}. Use one of: ${validStyles.join(', ')}`
        );
        return;
      }
  
      window.Telegram.WebApp.HapticFeedback.impactOccurred(style);
    },
  
    notificationOccurred: (type) => {
      if (!HapticSDK.isAvailable()) {
        HapticSDK.handleError(
          'Telegram WebApp is not available for haptic feedback'
        );
        return;
      }
  
      const validTypes = ['error', 'success', 'warning'];
      if (!validTypes.includes(type)) {
        HapticSDK.handleError(
          `Invalid notification type: ${type}. Use one of: ${validTypes.join(
            ', '
          )}`
        );
        return;
      }
  
      window.Telegram.WebApp.HapticFeedback.notificationOccurred(type);
    },
  
    selectionChanged: () => {
      if (!HapticSDK.isAvailable()) {
        HapticSDK.handleError(
          'Telegram WebApp is not available for haptic feedback'
        );
        return;
      }
  
      window.Telegram.WebApp.HapticFeedback.selectionChanged();
    },
  
    trigger: (type) => {
      switch (HapticType[type]) {
        case 'Selection':
          HapticSDK.selectionChanged();
          break;
        case 'Success':
          HapticSDK.notificationOccurred('success');
          break;
        case 'Warning':
          HapticSDK.notificationOccurred('warning');
          break;
        case 'Failure':
          HapticSDK.notificationOccurred('error');
          break;
        case 'LightImpact':
          HapticSDK.impactOccurred('light');
          break;
        case 'MediumImpact':
          HapticSDK.impactOccurred('medium');
          break;
        case 'HeavyImpact':
          HapticSDK.impactOccurred('heavy');
          break;
        case 'RigidImpact':
          HapticSDK.impactOccurred('rigid');
          break;
        case 'SoftImpact':
          HapticSDK.impactOccurred('soft');
          break;
        case 'None':
          break;
        default:
          HapticSDK.handleError(`Unknown haptic type: ${type}`);
      }
    },
  
    sequence: (effects) => {
      effects.forEach((effect, index) => {
        setTimeout(() => {
          HapticSDK.trigger(effect.type);
        }, effect.delay || index * 200); 
      });
    },
  };
  
  export default HapticSDK;