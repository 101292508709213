import React, { useEffect, useState, useRef } from 'react';

import useUrlParams from '../hooks/useUrlParams';

import { getBalance } from '../api/balance';
import { createUser, getAuth, setPhoto } from '../api/auth';

import {
  cloudGetItem,
  localGetItem,
  localSetItem,
} from '../utils/cloudStorage';
import GAMES_CONFIG from '../utils/GamesConfig';
import { GA4_KEY, storageKey } from '../utils/constants';
import { sendGTMEvent } from '../utils/analytics';

import styles from '../styles/Loading.module.css';

const PROGRESS_STEP = 0.5;

function Loading({
  setBalance,
  gameProgress,
  setFullLoaded,
  isInitialLoading = false,
}) {
  const { app_id } = useUrlParams();
  const [displayProgress, setDisplayProgress] = useState(0);
  const [targetProgress, setTargetProgress] = useState(0);
  const [loadingText, setLoadingText] = useState('Initializing...');

  const animationRef = useRef(null);

  const updateProgress = (step, text) => {
    if (!isInitialLoading) {
      setTargetProgress(step);
      setLoadingText(text);
    }
  };

  useEffect(() => {
    const animateProgress = () => {
      setDisplayProgress((prev) => {
        if (isInitialLoading) return 0;

        const nextValue = Math.min(
          prev + PROGRESS_STEP,
          Math.max(targetProgress, gameProgress)
        );

        if (nextValue < Math.max(targetProgress, gameProgress)) {
          animationRef.current = requestAnimationFrame(animateProgress);
        }

        return nextValue;
      });
    };

    animationRef.current = requestAnimationFrame(animateProgress);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [targetProgress, gameProgress, isInitialLoading]);

  useEffect(() => {
    if (!isInitialLoading) {
      const initializeApp = async () => {
        try {
          let token = localGetItem('Token');
          const telegram = window.Telegram.WebApp;
          const userData = telegram.initDataUnsafe.user;

          if (!token) {
            updateProgress(10, 'Authenticating...');
            await new Promise((resolve) => setTimeout(resolve, 500));
            const authData = await getAuth(userData);
            token = authData.token;
            localSetItem('Token', token, 3600 * 24);
            updateProgress(20, 'Authentication complete');
          } else {
            updateProgress(20, 'Using existing authentication');
          }

          await new Promise((resolve) => setTimeout(resolve, 500));
          updateProgress(30, 'Registering user...');
          cloudGetItem(storageKey.IS_CREATED).then(async (item) => {
            if (!item) {
              await createUser(userData);
            } else {
              return;
            }
          });

          if (userData.photo_url) {
            await new Promise((resolve) => setTimeout(resolve, 500));
            updateProgress(70, 'Updating user data...');
            await setPhoto(userData);
          }

          await new Promise((resolve) => setTimeout(resolve, 500));
          updateProgress(85, 'Fetching balance...');
          const balanceData = await getBalance(userData.id);
          setBalance(balanceData.balance);

          updateProgress(100, 'Loading game...');
        } catch (error) {
          console.error('Initialization error:', error);
          updateProgress(100, `Error: ${error.message}`);
        }
      };

      const telegram = window.Telegram.WebApp;
      if (telegram) {
        telegram.setHeaderColor('rgb(60, 157, 226)');
        telegram.setBackgroundColor('rgb(60, 157, 226)');

        window.scrollTo(0, 0);
        telegram.expand();
        initializeApp();
        sendGTMEvent(GA4_KEY.unity_initializate);
      }

      return () => {
        if (telegram) {
          telegram.setHeaderColor('#000000');
          telegram.setBackgroundColor('#000000');
        }
      };
    }
  }, [app_id, setBalance, isInitialLoading]);

  useEffect(() => {
    if (displayProgress >= 98 && gameProgress >= 98) {
      setFullLoaded(true);
    }
  }, [displayProgress, gameProgress, setFullLoaded]);

  const totalProgress = isInitialLoading
    ? 0
    : Math.round((displayProgress + gameProgress) / 2);

  return (
    <div className={styles.playScreen}>
      <div className={styles.version}>v1.0.0</div>

      <img
        className={styles.image}
        src={GAMES_CONFIG[app_id].logo}
        alt='Game logo'
      />

      {GAMES_CONFIG[app_id].title && (
        <h1 className={styles.title}>{GAMES_CONFIG[app_id].title}</h1>
      )}

      {GAMES_CONFIG[app_id].subtitle && (
        <p className={styles.subtitle}>{GAMES_CONFIG[app_id].subtitle}</p>
      )}

      <div className={styles.loaderWrapper}>
        <div className={styles.loaderContainer}>
          <div
            className={styles.loaderBar}
            style={{ width: `${totalProgress}%` }}
          />
          <div className={styles.loaderProgress}>{totalProgress}%</div>
        </div>
        <div className={styles.loaderText}>{loadingText}</div>
      </div>
    </div>
  );
}

export default Loading;
