import { useState, useEffect } from 'react';

export const useDevicePixelRatio = () => {
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );

  useEffect(() => {
    const updateDevicePixelRatio = () => {
      setDevicePixelRatio(window.devicePixelRatio);
    };

    const mediaMatcher = window.matchMedia(
      `screen and (resolution: ${devicePixelRatio}dppx)`
    );
    mediaMatcher.addEventListener('change', updateDevicePixelRatio);

    return () => {
      mediaMatcher.removeEventListener('change', updateDevicePixelRatio);
    };
  }, [devicePixelRatio]);

  return devicePixelRatio;
};
