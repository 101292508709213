import { useEffect } from 'react';

const usePaymentRequest = (
  setPaymentProduct,
  setIsVisible,
  setShowConnectWallet,
  addEventListener,
  removeEventListener
) => {
  useEffect(() => {
    const handlePaymentRequest = async (message) => {
      setPaymentProduct(message);
      setShowConnectWallet(false);
      setIsVisible(true);
    };

    addEventListener('PaymentRequest', handlePaymentRequest);
    return () => removeEventListener('PaymentRequest', handlePaymentRequest);
  }, [
    addEventListener,
    removeEventListener,
    setPaymentProduct,
    setIsVisible,
    setShowConnectWallet,
  ]);
};

export default usePaymentRequest;
