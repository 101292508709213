import React, { useEffect, useState } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';

import { useDevicePixelRatio } from '../hooks/useDevicePixelRatio';
import useUrlParams from '../hooks/useUrlParams';

import GAMES_CONFIG from '../utils/GamesConfig';
import { cloudGetItem, localGetItem } from '../utils/cloudStorage';
import { storageKey } from '../utils/constants';
import { checkTransactionStatus } from '../utils/payments';

import useGetBalance from '../hooks/useGetBalance';
import useGetLeaderboardData from '../hooks/useGetLeaderboardData';
import useGetProducts from '../hooks/useGetProducts';
import useHapticTrigger from '../hooks/useHapticTrigger';
import useInitializeApp from '../hooks/useInitializeApp';
import useLoadData from '../hooks/useLoadData';
import useLoadDataTelegram from '../hooks/useLoadDataTelegram';
import usePaymentRequest from '../hooks/usePaymentRequest';
import useSaveData from '../hooks/useSaveData';
import useSaveDataTelegram from '../hooks/useSaveDataTelegram';
import useSaveRefLinkToClipboard from '../hooks/useSaveRefLinkToClipboard';
import useShare from '../hooks/useShare';
import useShareToStory from '../hooks/useShareToStory';
import useShowRewardedAd from '../hooks/useShowRewardedAd';
import usePlayTappads from '../hooks/usePlayTappads';
import useLoadTappadsTasks from '../hooks/useLoadTappadsTasks';
import useShowAds from '../hooks/useShowAds';

import Loading from '../components/Loading';
import Popup from '../components/Popup';
import Modal from '../components/Modal';
import Tads from '../components/Tads';
import Tappads from '../components/Tappads';

import styles from '../styles/GamePage.module.css';

function GamePage({ initialBalance = null }) {
  const { app_id } = useUrlParams();
  const devicePixelRatio = useDevicePixelRatio();

  const [balance, setBalance] = useState(initialBalance);
  const [paymentProduct, setPaymentProduct] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [AdNumber, setAdNumber] = useState(null);

  const [fullLoaded, setfullLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [showConnectWallet, setShowConnectWallet] = useState(false);

  const handleCacheControl = (url) => {
    if (
      url.match(/\.data/) ||
      url.match(/\.loader/) ||
      url.match(/\.framework/) ||
      url.match(/\.wasm/)
    ) {
      const cache = localGetItem(`${storageKey.UNITY_CACHE}${app_id}`);
      return !cache ? 'must-revalidate' : 'immutable';
    }
  };

  const {
    unityProvider,
    loadingProgression,
    isLoaded,
    sendMessage,
    addEventListener,
    removeEventListener,
  } = useUnityContext({
    ...GAMES_CONFIG[app_id],
    productVersion: '1.0',
    devicePixelRatio,
    cacheControl: handleCacheControl,
  });

  useEffect(() => {
    if (isLoaded) {
      cloudGetItem(storageKey.PAYMENT_HASH).then(async (item) => {
        if (item && item?.hash && item?.paymentProduct) {
          setIsVisible(true);
          setIsLoading(true);
          setPaymentProduct(item.paymentProduct);
          await checkTransactionStatus(
            item.hash,
            item.paymentProduct,
            setPaymentProduct,
            sendMessage,
            setIsLoading,
            setIsVisible
          );
        }
      });
    }
  }, [isLoaded, sendMessage, setIsLoading, setIsVisible]);

  useInitializeApp(
    isLoaded,
    sendMessage,
    balance,
    addEventListener,
    removeEventListener
  );
  useGetProducts(isLoaded, sendMessage, addEventListener, removeEventListener);
  useGetBalance(
    isLoaded,
    sendMessage,
    balance,
    setBalance,
    addEventListener,
    removeEventListener
  );
  useShare(addEventListener, removeEventListener);
  useShareToStory(addEventListener, removeEventListener);
  useSaveRefLinkToClipboard(addEventListener, removeEventListener);
  useGetLeaderboardData(
    isLoaded,
    sendMessage,
    addEventListener,
    removeEventListener
  );
  useLoadData(isLoaded, sendMessage, addEventListener, removeEventListener);
  useSaveData(addEventListener, removeEventListener);
  useSaveDataTelegram(addEventListener, removeEventListener);
  useLoadDataTelegram(
    isLoaded,
    sendMessage,
    addEventListener,
    removeEventListener
  );
  usePaymentRequest(
    setPaymentProduct,
    setIsVisible,
    setShowConnectWallet,
    addEventListener,
    removeEventListener
  );
  useShowRewardedAd(
    isLoaded,
    sendMessage,
    addEventListener,
    removeEventListener
  );
  usePlayTappads(addEventListener, removeEventListener);
  useLoadTappadsTasks(
    isLoaded,
    sendMessage,
    addEventListener,
    removeEventListener
  );
  useShowAds(
    isLoaded,
    sendMessage,
    setIsModalVisible,
    setAdNumber,
    addEventListener,
    removeEventListener
  );
  useHapticTrigger(addEventListener, removeEventListener);

  return (
    <div className={styles.container}>
      {!fullLoaded && (
        <Loading
          setBalance={setBalance}
          gameProgress={Math.floor(loadingProgression * 100)}
          setFullLoaded={setfullLoaded}
        />
      )}
      <Unity
        unityProvider={unityProvider}
        devicePixelRatio={devicePixelRatio}
        className={styles.unityContainer}
      />
      <div className={styles.popupContainer}>
        <Popup
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          sendMessage={sendMessage}
          paymentProduct={paymentProduct}
          setPaymentProduct={setPaymentProduct}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          showConnectWallet={showConnectWallet}
          setShowConnectWallet={setShowConnectWallet}
        />
      </div>
      <Modal
        isVisible={isModalVisible}
        onClose={() => {
          sendMessage(
            'TeleLauncherSDK',
            'OnAdsResult',
            JSON.stringify({
              done: false,
            })
          );
          setIsModalVisible(false);
          setAdNumber(null);
        }}
        title='Choose your ad'
      >
        {AdNumber === 2 && (
          <Tads
            setIsModalVisible={setIsModalVisible}
            setAdNumber={setAdNumber}
            sendMessage={sendMessage}
          />
        )}
        {AdNumber === 3 && (
          <Tappads
            setIsModalVisible={setIsModalVisible}
            setAdNumber={setAdNumber}
            sendMessage={sendMessage}
          />
        )}
      </Modal>
    </div>
  );
}

export default GamePage;
