import { useEffect } from 'react';

import { DEFAULT_USER, GA4_KEY } from '../utils/constants';
import GAMES_CONFIG from '../utils/GamesConfig';
import { sendGTMEvent } from '../utils/analytics';

import useUrlParams from './useUrlParams';
import PromoHandlerUtility from "../utils/promoHandlerUtility";

const useShare = (addEventListener, removeEventListener) => {
  const { app_id } = useUrlParams();
  useEffect(() => {
    const handleShare = (message) => {
      const url = `${GAMES_CONFIG[app_id || 1].telegramUrl}/app?startapp=${
        window.Telegram.WebApp.initDataUnsafe.user?.id || DEFAULT_USER.id
      }`;

      const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
        url
      )}&parse_mode=Markdown&text=${encodeURIComponent(
        message
          ? GAMES_CONFIG[app_id || 1][message]
            ? GAMES_CONFIG[app_id || 1][message]
            : message
          : GAMES_CONFIG[app_id || 1].shareDefaultText
      )}`;
      sendGTMEvent(GA4_KEY.untity_share);

      // sendPromoCallback for tappads when sharing
      PromoHandlerUtility.sendPromoCallback(window.Telegram.WebApp.initDataUnsafe.user?.id || DEFAULT_USER.id);
      window.open(shareUrl, '_blank');
    };

    addEventListener('Share', handleShare);

    return () => removeEventListener('Share', handleShare);
  }, [addEventListener, removeEventListener, app_id]);
};

export default useShare;
