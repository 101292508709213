import { useEffect, useState } from 'react';

import { getSave } from '../api/saves';

import useUrlParams from './useUrlParams';

import { cloudGetItem, cloudSetItem } from '../utils/cloudStorage';
import { DEFAULT_USER, storageKey } from '../utils/constants';

const useLoadData = (
  isLoaded,
  sendMessage,
  addEventListener,
  removeEventListener
) => {
  const { app_id } = useUrlParams();
  const [saveData, setSaveData] = useState('');

  useEffect(() => {
    if (!!isLoaded && !!saveData) {
      cloudGetItem(storageKey.SAVE_DATA)
        .then((item) => {
          if (item !== null) {
            sendMessage('TeleLauncherSDK', 'OnSavesLoad', item);
          } else {
            sendMessage('TeleLauncherSDK', 'OnSavesLoad', saveData);
          }
        })
        .catch((error) => {
          console.error('Error retrieving data from Telegram Storage:', error);
          sendMessage('TeleLauncherSDK', 'OnSavesLoad', saveData);
        });
    }
  }, [isLoaded, saveData, sendMessage]);

  useEffect(() => {
    const handleLoadSave = async () => {
      const result = await cloudGetItem(storageKey.SAVE_DATA);
      if (!result) {
        const data = await getSave(
          window.Telegram.WebApp.initDataUnsafe.user.id || DEFAULT_USER.id,
          app_id
        );

        if (data && data.data) {
          setSaveData(JSON.stringify(data.data));
          cloudSetItem(storageKey.SAVE_DATA, JSON.stringify(data.data));
        }
      } else {
        setSaveData(result);
      }
    };

    addEventListener('LoadData', handleLoadSave);
    return () => removeEventListener('LoadData', handleLoadSave);
  }, [addEventListener, removeEventListener, app_id]);
};

export default useLoadData;
