import { useCallback, useRef } from 'react';

import { adsgramKey } from '../utils/constants';

const useAdsgram = () => {
  const AdControllerRef = useRef(undefined);

  const initAd = useCallback((blockId) => {
    if (window.Adsgram) {
      AdControllerRef.current = window.Adsgram.init({ blockId });
    }
  }, []);

  const showAd = useCallback(
    ({ blockName }) => {
      initAd(
        !!adsgramKey[blockName]
          ? adsgramKey[blockName]
          : adsgramKey['initial_value']
      );

      if (AdControllerRef.current) {
        return AdControllerRef.current
          .show()
          .then((result) => {
            return { ...result, placement: blockName || '' };
          })
          .catch((result) => {
            return { ...result, placement: blockName || '' };
          });
      } else {
        return {
          error: true,
          done: false,
          state: 'load',
          description: 'Adsgram script not loaded',
          placement: blockName || '',
        };
      }
    },
    [initAd]
  );

  return showAd;
};

export default useAdsgram;
