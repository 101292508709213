import makeRequest from './apiConfig';

const leaderboardEndpoints = {
  getLeaderboard: '/api/leaderboard',
};

export const getLeaderboard = async () => {
  try {
    const response = await makeRequest({
      method: 'GET',
      endpoint: leaderboardEndpoints.getLeaderboard,
    });

    return response;
  } catch (error) {
    console.error('Failed to get leaderboard data:', error);
    throw error;
  }
};
