import { useEffect, useState } from 'react';

import { getBalance } from '../api/balance';

import { DEFAULT_USER, GA4_KEY } from '../utils/constants';
import { sendGTMEvent } from '../utils/analytics';

const useGetBalance = (
  isLoaded,
  sendMessage,
  balance,
  setBalance,
  addEventListener,
  removeEventListener
) => {
  const [isLoadedBalance, setIsLoadedBalance] = useState(false);
  useEffect(() => {
    if (!!isLoaded && !!isLoadedBalance) {
      sendMessage('TeleLauncherSDK', 'OnGetBalance', +balance);
      setIsLoadedBalance(false);
      sendGTMEvent(GA4_KEY.unity_get_balance);
    }
  }, [isLoaded, balance, isLoadedBalance, sendMessage]);

  useEffect(() => {
    const handleGetBalance = async () => {
      const data = await getBalance(
        window.Telegram.WebApp.initDataUnsafe.user.id || DEFAULT_USER.id
      );

      if (data) {
        setBalance(+data.balance);
        setIsLoadedBalance(true);
      }
    };

    addEventListener('GetBalance', handleGetBalance);
    return () => removeEventListener('GetBalance', handleGetBalance);
  }, [addEventListener, removeEventListener, setBalance]);
};

export default useGetBalance;
