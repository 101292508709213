import makeRequest from './apiConfig';

const unityCacheEndpoints = {
  getUnityCache: '/api/get-etag',
};

export const getAllCache = async (app_id) => {
  try {
    const response = await makeRequest({
      method: 'GET',
      endpoint: `${unityCacheEndpoints.getUnityCache}?app_id=${app_id}`,
    });

    console.log(response);
    return response;
  } catch (error) {
    console.error('Сache retrieval failed:', error);
    throw error;
  }
};
