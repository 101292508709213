const GAMES_CONFIG = {
  1: {
    loaderUrl:
      'https://games.telelauncher.com/testgame-gzip/Build/TeleLauncherUnity-Gzip.loader.js',
    dataUrl:
      'https://games.telelauncher.com/testgame-gzip//Build/TeleLauncherUnity-Gzip.data.unityweb',
    frameworkUrl:
      'https://games.telelauncher.com/testgame-gzip//Build/TeleLauncherUnity-Gzip.framework.js.unityweb',
    codeUrl:
      'https://games.telelauncher.com/testgame-gzip//Build/TeleLauncherUnity-Gzip.wasm.unityweb',
    assertsUrl: '',
    logo: '/logo.jpg',
    title: 'TIME MASTER',
    subtitle: 'BY PLAYTIVITI',
    telegramUrl: 'https://t.me/TimeMasterCoinBot',
    shareDefaultText: 'Welcome to our application',
    diamond: 'Get 1$ or 50 💎 for FREE by join Time Master!',
  },
  2: {
    loaderUrl:
      'https://games.telelauncher.com/kill-them-all-gzip/public/Build/KillThemAll.loader.js',
    dataUrl:
      'https://games.telelauncher.com/kill-them-all-gzip/public/Build/KillThemAll.data.unityweb',
    frameworkUrl:
      'https://games.telelauncher.com/kill-them-all-gzip/public/Build/KillThemAll.framework.js.unityweb',
    codeUrl:
      'https://games.telelauncher.com/kill-them-all-gzip/public/Build/KillThemAll.wasm.unityweb',
    assertsUrl: 'https://games.telelauncher.com/kill-them-all-gzip/public',
    logo: 'https://raw.githubusercontent.com/dangost/static/refs/heads/master/ST-icon-1.png',
    title: 'Kill Them All',
    subtitle: 'BY PANDAPLAY',
    telegramUrl: 'https://t.me/kill_them_all_bot',
    shareDefaultText: 'Welcome to our application',
    diamond: 'Get 1$ or 50 💎 for FREE by join Kill Them All!',
  },
};

export default GAMES_CONFIG;
