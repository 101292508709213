import React from 'react';
import { TadsWidget } from 'react-tads-widget';

import styles from '../styles/Tads.module.css';

const Tads = ({ setIsModalVisible, setAdNumber, sendMessage }) => {
  const [tadsError, setTadsError] = React.useState(null);
  return (
    <div className={styles.tadsContainer}>
      <div className={styles.loader}>
        <div className={styles.spinnerBorder} role='status' />
      </div>

      {tadsError && <div className={styles.errorMessage}>{tadsError}</div>}
      <TadsWidget
        id={193}
        debug={true}
        onLoad={() => {
          setTadsError(null);
        }}
        onClickReward={(e) => {
          console.log('tads, Клик по награде', e);
          sendMessage(
            'TeleLauncherSDK',
            'OnAdsResult',
            JSON.stringify({
              done: true,
            })
          );
          setAdNumber(null);
          setIsModalVisible(false);
        }}
        onAdsNotFound={(e) => {
          setTadsError("Sorry, we don't have ads for you at the moment.");
        }}
      />
    </div>
  );
};

export default Tads;
