import { useEffect } from 'react';

import { setSave } from '../api/saves';

import useUrlParams from './useUrlParams';

import { cloudSetItem } from '../utils/cloudStorage';
import { DEFAULT_USER, GA4_KEY, storageKey } from '../utils/constants';
import { sendGTMEvent } from '../utils/analytics';

const useSaveData = (addEventListener, removeEventListener) => {
  const { app_id } = useUrlParams();

  useEffect(() => {
    const handleGetSave = async (message) => {
      if (message) {
        cloudSetItem(storageKey.SAVE_DATA, message);
        await setSave(
          window.Telegram.WebApp.initDataUnsafe.user.id || DEFAULT_USER.id,
          app_id,
          JSON.parse(message)
        );
        sendGTMEvent(GA4_KEY.unity_save_data);
      }
    };
    addEventListener('SaveData', handleGetSave);
    return () => removeEventListener('SaveData', handleGetSave);
  }, [addEventListener, removeEventListener, app_id]);
};

export default useSaveData;
