import { useEffect } from 'react';

import { DEFAULT_USER, GA4_KEY } from '../utils/constants';
import GAMES_CONFIG from '../utils/GamesConfig';
import { sendGTMEvent } from '../utils/analytics';

import useUrlParams from './useUrlParams';

const useShareToStory = (addEventListener, removeEventListener) => {
  const { app_id } = useUrlParams();
  useEffect(() => {
    const handleShareToStory = (mediaUrl) => {
      if (!window.Telegram || !window.Telegram.WebApp) {
        alert('This function is only available in the Telegram app.');
        return;
      }

      try {
        const linkUrl = `${GAMES_CONFIG[app_id || 1].telegramUrl}/app?startapp=${
          window.Telegram.WebApp.initDataUnsafe.user?.id || DEFAULT_USER.id
        }`;
        const text = `Join 🚀 TimeMaster - ${linkUrl}`;

        const params = {
          text: text,
          widget_link: {
            url: linkUrl,
            name: 'Learn more',
          },
        };

        window.Telegram.WebApp.shareToStory(mediaUrl, params);
        sendGTMEvent(GA4_KEY.untity_share_story);
      } catch (error) {
        alert(`Error while publishing: ${error.message}`);
      }
    };

    addEventListener('ShareToStory', handleShareToStory);
    return () => removeEventListener('ShareToStory', handleShareToStory);
  }, [addEventListener, removeEventListener, app_id]);
};

export default useShareToStory;
