import makeRequest from './apiConfig';

const balanceEndpoints = {
  getBalance: '/api/balance',
};

export const getBalance = async (id) => {
  try {
    const response = await makeRequest({
      method: 'GET',
      endpoint: `${balanceEndpoints.getBalance}/${id}`,
    });

    return response;
  } catch (error) {
    console.error('Failed to get balance:', error);
    throw error;
  }
};
