import makeRequest from './apiConfig';

const paymentEndpoints = {
  getPaymentProducts: '/api/products',
  createPayment: '/api/orders/create',
  getPaymentStatus: '/api/orders/status',
};

export const getPaymentProducts = async (products) => {
  try {
    const response = await makeRequest({
      method: 'POST',
      endpoint: paymentEndpoints.getPaymentProducts,
      body: {
        products: products,
      },
    });

    return response;
  } catch (error) {
    console.error('Failed to get payment products:', error);
    throw error;
  }
};

export const createPayment = async (productName, stars) => {
  const searchParams = new URLSearchParams(window.location.search);
  const app_id = searchParams.get('app_id');
  try {
    const response = await makeRequest({
      method: 'POST',
      endpoint: paymentEndpoints.createPayment,
      body: {
        productName: productName,
        app_id: app_id,
        stars: stars,
      },
    });
    return response;
  } catch (error) {
    console.error('Failed to create payment products:', error);
    throw error;
  }
};

export const getPaymentStatus = async (hash) => {
  try {
    const response = await makeRequest({
      method: 'GET',
      endpoint: `${paymentEndpoints.getPaymentStatus}?order=${hash}`,
    });

    return response;
  } catch (error) {
    console.error('Failed to get payment status:', error);
    throw error;
  }
};
