import { localGetItem, localSetItem } from './cloudStorage';
import { storageKey } from './constants';

const TAPPS_PROMO_PREFIX = 'promo_';
const CALLBACK_URL = 'https://wallapi.tappads.io/v1/tapp-cpa';

export const promoHandlerUtility = {
  handlePromoCode: (promoCode, userId, isExistingUser) => {
    const existingPromoData = localGetItem(`${storageKey.PROMO_DATA}${userId}`);

    if (existingPromoData && existingPromoData.callbackSent) {
      console.log(
        `Callback already sent for user ${userId}, skipping promo code handling`
      );
      return;
    }

    if (existingPromoData && !existingPromoData.callbackSent) {
      console.log(
        `Promo data already exists for user ${userId}, but callback not sent. Keeping existing data.`
      );
      return;
    }

    if (promoCode && promoCode.startsWith(TAPPS_PROMO_PREFIX)) {
      const [, publisher, clickId] = promoCode.split('_');

      if (publisher && clickId) {
        localSetItem(
          `${storageKey.PROMO_DATA}${userId}`,
          {
            publisher,
            clickId,
            isExistingUser,
            callbackSent: false,
          },
          30 * 24 * 60 * 60
        ); // Store for 30 days
        console.log(
          `Promo data stored for user ${userId}: Publisher - ${publisher}, ClickID - ${clickId}, Existing User - ${isExistingUser}`
        );
      }
    } else {
      console.log(`Invalid or non-TApps promo code: ${promoCode}`);
    }
  },

  sendPromoCallback: async (userId) => {
    const promoData = localGetItem(`${storageKey.PROMO_DATA}${userId}`);

    if (promoData && promoData.callbackSent) {
      console.log(`Callback already sent for user ${userId}, skipping`);
      return;
    }

    if (promoData && promoData.clickId) {
      const baseUrl = `${CALLBACK_URL}?click_id=${promoData.clickId}`;
      const url = promoData.isExistingUser ? `${baseUrl}&is_old=true` : baseUrl;

      try {
        const response = await fetch(url, { method: 'GET' });
        if (response.ok) {
          console.log(`Promo callback sent successfully for user ${userId}`);
          localSetItem(
            `${storageKey.PROMO_DATA}${userId}`,
            {
              ...promoData,
              callbackSent: true,
            },
            365 * 24 * 60 * 60
          ); // Store for a year
        } else {
          console.error(`Failed to send promo callback for user ${userId}`);
        }
      } catch (error) {
        console.error(
          `Error sending promo callback for user ${userId}:`,
          error
        );
      }
    } else {
      console.log(
        `No valid promo data found for user ${userId}, skipping callback`
      );
    }
  },
};

export default promoHandlerUtility;
