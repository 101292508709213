export const storageKey = {
  PAYMENT_HASH: 'paymentHash',
  SAVE_DATA: 'save',
  SAVE_CACHE: 'saveCache',
  UNITY_CACHE: 'unityCache',
  PROMO_DATA: 'promoData_',
  IS_CREATED: 'isCreated',
};

export const adsgramKey = {
  revive_hero: '4978',
  initial_value: '4978',
};

export const DEFAULT_USER = {
  id: 252,
  first_name: '123',
  last_name: '345',
  username: 'asdw',
  language_code: 'ru',
  allows_write_to_pm: true,
  is_premium: true,
  photo_url: 'hey.com',
};

export const GA4_KEY = {
  unity_update: {
    category: 'UPDATE_UNITY_APP',
    action: 'clear cache and download new version',
  },
  unity_initializate: {
    category: 'INITIAL_UNITY_APP',
    action: 'initializate app',
  },
  unity_added_script: {
    category: 'ADD_UNITY_SCRIPT',
    action: 'add a third-party script ',
  },
  unity_get_products: {
    category: 'GET_UNITY_PRODUCTS',
    action: 'GetProducts/OnProductsGet events from Unity',
  },
  unity_get_balance: {
    category: 'GET_UNITY_BALANCE',
    action: 'GetBalance/OnGetBalance event from Unity',
  },
  untity_share: {
    category: 'SHARE',
    action: 'share to telegram chat',
  },
  untity_share_story: {
    category: 'SHARE',
    action: 'share to telegram story',
  },
  unity_save_link_to_clipboard: {
    category: 'SAVE_LINK_TO_CLIPBOARD',
    action: 'save link to clipboard',
  },
  unity_get_leaderboard: {
    category: 'GET_LEADERBOARD',
    action: 'GetLeaderboardData/LoadLeaderboardData event from Unity',
  },
  unity_load_save: {
    category: 'LOAD_SAVE',
    action: 'LoadData/OnSavesLoad event from Unity',
  },
  unity_save_data: {
    category: 'SAVE_DATA',
    action: 'Save to unity and telegram storage',
  },
  unity_save_data_to_telegram_storage: {
    category: 'SAVE_UNITY_DATA',
    action: 'Save unity data to telegram storage',
  },
  unity_send_telegram_storage: {
    category: 'SEND_TELEGRAM_STORAGE_DATA',
    action: 'send data from telegram storage to unity',
  },
  payment: (productName, stars = false) => {
    return {
      category: `creted payment for ${stars ? 'stars' : 'ton'}`,
      action: `payment for ${productName}`,
    };
  },
};
